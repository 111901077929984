import { Text } from "@components/ui";
import cn from "clsx";
import { asHTML, Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import Image from "next/image";
import promoIcon from "@static/images/icons/promo.png";
import { useEffect, useState } from "react";
import { useCustomer, usePromotionDetails } from "@middleware/hooks";
import {
  getFormattedPrice,
  isSegmentCustomerProspect,
} from "@middleware/helpers";
import { useRouter } from "next/router";
import {
  URL_PAGE_MEN,
  URL_PAGE_REGIME,
  URL_PAGE_WOMEN,
} from "@middleware/constants";
import TopPartBar from "./TopPartBar";
import style from "./BarPromotion.module.scss";

export type BarPromotionProps = SliceComponentProps<Content.BarPromotionSlice>;

const BarPromotion = ({ slice }: BarPromotionProps): JSX.Element => {
  const initPromotionText = asHTML(slice.primary.text);
  const { promotion } = usePromotionDetails();
  const [promotionText, setPromotionText] = useState(initPromotionText);
  const [isProgramPage, setIsProgramPage] = useState(false);
  const { customer } = useCustomer();
  const router = useRouter();
  const isProspect = isSegmentCustomerProspect(customer);

  const showBottomPart =
    isProspect || (!isProgramPage && initPromotionText.length > 0);

  useEffect(() => {
    setIsProgramPage(
      router.pathname === URL_PAGE_MEN ||
        router.pathname === URL_PAGE_WOMEN ||
        router.pathname === URL_PAGE_REGIME,
    );
  }, [router.pathname]);

  useEffect(() => {
    document.body.classList.add("showBarPromotion");
  }, []);

  useEffect(() => {
    if (promotion) {
      const updatedText = initPromotionText.replace(
        /<strong>[\s\S]*?<\/strong>/,
        "<strong>-" + getFormattedPrice(promotion.discountAmount) + "</strong>",
      );
      setPromotionText(updatedText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promotion]);

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="bar-promotion"
    >
      <div className={cn(style.bar, { [style.showTopPart]: true })}>
        <TopPartBar isProgramPage={isProgramPage} slice={slice} />
        {showBottomPart && (
          <div className={style.bottomPart}>
            <div className="lk-wrap">
              <div className={style.flex}>
                <div className={style.icon}>
                  <Image src={promoIcon} alt="" />
                </div>
                <div className={style.text}>
                  <Text variant="body" html={promotionText} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default BarPromotion;
