import React from "react";
import { Button, Link, Text } from "@components/ui";
import { useTranslation } from "react-i18next";
import cn from "clsx";
import { BILAN_SECTION_ID, URL_PAGE_DURATION } from "@middleware/constants";
import { scrollToSection } from "@middleware/helpers";
import { PrismicLink } from "@prismicio/react";
import { BarPromotionSlice } from "../../../prismicio-types";
import style from "./BarPromotion.module.scss";

interface Props {
  slice: BarPromotionSlice;
  isProgramPage: boolean;
}
function TopPartBar({ slice, isProgramPage }: Props) {
  const { t } = useTranslation();
  const buttonsText = slice.primary.buttonsText;
  const hasCtaUrl = slice.primary.ctaUrl.link_type !== "Any";
  const hasCtaUrl2 = slice.primary.ctaUrl2.link_type !== "Any";

  return (
    <div
      className={cn(style.topPart, { [style.isProgramPage]: isProgramPage })}
    >
      <div className="lk-wrap">
        <div className={style.flex}>
          {isProgramPage ? (
            <div className={cn(style.actions, style.hasOnlyButtons)}>
              <Button
                variant="secondary"
                type="button"
                onClick={() => scrollToSection(BILAN_SECTION_ID)}
              >
                {t("buttons.doingAssessment")}
              </Button>
              <Link className="primary" href={URL_PAGE_DURATION}>
                {t("buttons.chooseProgram")}
              </Link>
            </div>
          ) : (
            <>
              {buttonsText !== "" && (
                <div className={style.actionsLabel}>
                  <Text>{buttonsText}</Text>
                </div>
              )}
              <div className={style.actions}>
                {slice.primary.ctaText !== "" && hasCtaUrl && (
                  <PrismicLink
                    className={style.primary}
                    field={slice.primary.ctaUrl}
                  >
                    {slice.primary.ctaText}
                  </PrismicLink>
                )}
                {slice.primary.ctaText2 !== "" && hasCtaUrl2 && (
                  <PrismicLink
                    className={style.primary}
                    field={slice.primary.ctaUrl2}
                  >
                    {slice.primary.ctaText2}
                  </PrismicLink>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopPartBar;
